export const SaveUrl={
  DEFAULT:"DEFAULT",
  SCORE:"SCORE",
  USER_IMAGE:"USER_IMAGE"
}

export const ScorePower={
  PUBLIC:{
    value:"PUBLIC",
    tag:"公开"
  },
  PRIVATE:{
    value:"PRIVATE",
    tag:"私密"
  }
}

export const ScoreStatus={
  APPROVED:{
    value:"APPROVED",
    tag:"审核通过"
  },
  UNDER_REVIEW:{
    value:"UNDER_REVIEW",
    tag:"审核中"
  }
}