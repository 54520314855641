<template>
    <div class='uploadScore'>
       <div class="upload">
        <ul class="left">
            <li><span>乐曲名称：</span><input v-model="score.name"/></li>
            <li><span>作者/出处：</span><input v-model="score.author"/></li>
            <li><span>乐谱类型：</span>
              <select v-model="score.typeId">
                <template v-if="type">
                  <option v-for="t,i of type" :key="i" :value="t.id">{{t.type}}</option>
                </template>
              </select>
            </li>
        </ul>
        <ul class="right">
            <li>
              <span>是否公开：</span>
              <select v-model="score.power">
                <option v-for="(val,key) in ScorePower" :value="val.value" :key="key">{{ val.tag }}</option>
              </select>
            </li>
            <li><PButton @click="startUpload" size="mini" :active="btnActive" :hoverActive='false'>{{btnActive==true?'开始上传':'上传中...'}}</PButton></li>
        </ul>
       </div>
       <div class="score">
          <template v-if="images&&images.length" >
            <div class="img" v-for="i in images.length" :key="i" >
                <!-- <img :src='images[i-1]' :ref="setImgRef"/> -->
                <img :src='images[i-1].src'/>
                <div class="dele" @click="remove(i-1)">×</div>
            </div>
          </template>
          <div v-if="tick" class="addScore">
            <input accept=".jpg,.png,.gif,.webp" type="file" hidden ref="inp" @change="addScoreInp"/>
            <div class="but" @click="btnActive==true?inp.click():''">
                <h3>♫ ♩ ♪ ♬</h3>
                <h1>+</h1>
                <h2>添加乐谱</h2>
            </div>
        </div>
       </div>
    </div>
</template>

<script>
//import {useStore} from 'vuex'
import {ref,nextTick, reactive} from 'vue'
//import {uploadImage,getScoreType,addScore,addScoreImage} from '../../../../api/score'
//import {useRouter} from 'vue-router'
import Message from '../../../../components/library/Message'
import { uploadFile } from '../../../../utils/file'
import { SaveUrl,ScorePower } from '../../../../enum';
import axios from '../../../../utils/request';
export default {
  name: 'uploadScore',
  setup(){
    let images=ref([]);
    let inp=ref(null);
    let tick=ref(true);
    let btnActive=ref(true);
    const addScoreInp=(e)=>{
        let file=e.target.files[0];
        //console.log("file0");
        //console.log(file);
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let image={src:null,file:null};
            image.src=reader.result;
            //console.log(image.src);
            image.file=file;
            //console.log(file.name);
            images.value.push(image);
            //saveImg(image);
            //console.log(images.value);
            //store.commit('score/setImages',images.value);
            //store.state.score.images=images.value;//这样直接赋值无法持久化
        };
        tick.value=false;
        nextTick(()=>{
            tick.value=true;
        })
    }
    const remove=(index)=>{
        images.value.splice(index,1);
    }

    
    //const store=useStore();
    //console.log("userid:"+store.state.user.loginUser.id);
    let score=reactive({
      id:null,
      name:'',
      author:'',
      typeId:null,
      power:ScorePower.PUBLIC.value
    })

    let type=ref(null);
    
    // getScoreType().then((res)=>{
    //   type.value=res.data;
    //   score.typeId=res.data[0].id;
    // })

    axios.get("/scoreType/getScoreTypeList").then((data)=>{
      type.value=data;
      score.typeId=type.value[0].id;
    })

    const uploadImages=async (images)=>{
      let fileList=[]
      for(let i=0;i<images.length;i++){
        await uploadFile(images[i].file,SaveUrl.SCORE,"score").then((data)=>{
          fileList.push(data);
        }).catch((err)=>{
          throw err;
        })
      }
      return fileList;
    }

    // const uploadImages=async (images,scoreId)=>{
    //   console.log("uploadImages...");
    //   let sum=0;
    //   for(let img of images){
    //     let res=await uploadImage(img.file);
    //     if(res.result=="success"){
    //       res.data.scoreId=scoreId;
    //       console.log("image:");
    //       console.log(res.data);
    //       await addScoreImage(res.data).then(()=>{
    //         sum++;
    //       })
    //     }
    //   }
    //   return sum;
    // }

    let trim=(str)=>
    { 
      return str.replace(/(^\s*)|(\s*$)/g, ""); 
    }

    let check=()=>{
      score.name=trim(score.name);
      score.author=trim(score.author);
      if(images.value.length==0){
        Message({type:'warn',text:'请添加乐谱'});
        return false;
      }else if(score.name==''){
        Message({type:'warn',text:'请输入乐曲名称'});
        return false;
      }else if(score.author==''){
        Message({type:'warn',text:'请输入作者/出处'});
        return false;
      }else{
        return true;
      }
    }
    //const router=useRouter();
    const startUpload=()=>{
      // if(!store.state.user.loginUser){
      //   router.push('/login');
      //   return;
      // }
      if(btnActive.value==false){
        return;
      }
      if(!check()){
        return;
      }
      btnActive.value=false;

      uploadImages(images.value).then(async (fileList)=>{
        let scoreImageList=[];
        for(let file of fileList){
          let scoreImage={fileId:file.id};
          scoreImageList.push(scoreImage);
        }
        score.scoreImageList=scoreImageList;
        await axios.post("/score/addScore",score).then((data)=>{
          score=data;
          Message({type:"success",text:"添加成功"});
        }).catch((err)=>{
          throw err;
        })
      }).catch((err)=>{
        Message({type:"error",text:err.message});
        console.log(err);
      }).finally(()=>{
        btnActive.value=true;
      })
      // addScore(score).then(async (res)=>{
      //   console.log("addScore...");
      //   console.log(res.data);
      //   let sum=0;
      //   sum= await uploadImages(images.value,res.data.id);
      //   if(sum<images.value.length){
      //     Message({type:'warn',text:'部分乐谱图片上传失败！'});
      //   }else{
      //     Message({type:'success',text:'上传成功'})
      //   }
      //   //console.log(res.data.id);
      //   btnActive.value=true;
      // }).catch(()=>{
      //   Message({type:'error',text:'服务器启动中，请稍后再试'});
      //   btnActive.value=true;
      // })
      ////uploadImage(images.value[0].file);
    }

    // watch(()=>{return store.state.user.loginUser},(newVal)=>{
    //   if(newVal)
    //     score.userId=newVal.id;
    // },{immediate:true})
    return {addScoreInp,inp,tick,images,remove,startUpload,score,type,btnActive,ScorePower}
  }
}
</script>

<style scoped lang='less'>
.upload{
  width: 900px;
  height: 110px;
  //border:solid;
  border-bottom:solid 2px #d4d4d4;
  display:flex;
  span{
    font-weight: 800;
  }
  li{
    margin-bottom: 10px;
  }
  .right{
    margin-left: 20px;
    li{
        margin-bottom: 30px;
    }
  }
}
.score{
  width: 900px;
  padding: 10px;
  //height: 200px;
  //border:solid;
  .img{
    width: 150px;
    height: 200px;
    float: left;
    position: relative;
    margin: 3px;
    box-shadow: 0 3px 8px rgba(0,0,0,0.2);
    .dele{
        width: 40px;
        height: 40px;
        background-color: red;
        opacity: 0;
        position:absolute;
        right: 0;
        top:0;
        line-height: 40px;
        text-align: center;
        color: white;
        font-size: 30px;
    }
    .dele:hover{
        cursor: pointer;
    }
  }
  .img:hover{
      .dele{
          opacity: 1;
      }
  }
}
.but{
    float: left;
    width: 150px;
    height: 200px;
    background-color: rgb(213, 213, 217);
    margin: 5px;
    h1{
        line-height: 120px;
        font-size: 200px;
        color: white;
        text-align: center;
        font-weight:50;
    }
    h2{
        line-height: 20px;
        color: white;
        text-align: center;
    }
    h3{
        line-height: 20px;
        color: white;
        text-align: center;
    }
}

.but:hover{
    cursor:pointer;
}
</style>