import axios from "./request";

export const uploadFile=function(file,saveUrl,remarks){
    let p=new Promise((resolve,reject)=>{
        let formData=new FormData();
        formData.append("file",file);
        formData.append("saveUrl",saveUrl);
        formData.append("remarks",remarks);
        axios.post("/file/uploadFile",formData).then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err);
        })
    })
    return p;
}